import React from 'react';
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import Store from "../../../../../Common/Store";
import APICall from "../../../../../Common/APICall";
import CslSimpleDropDown from '../../../../Common/CslSimpleDropDown';


const ModalWraper = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	width: 70%;
	position: absolute;
	z-index: 1001;
`;
const MRModalContainer = styled.div`
	display: block;
	box-sizing: border-box;
	height: auto;
	background-color: rgb(243, 247, 251);
	box-shadow: rgb(167, 166, 166) 0px 0px 5px;
`;
const MRModalHeader = styled.div`
	background-color: white;
	display: block;
	padding-top: 5px;
	font-size: 14px;
	height: 100px;
	width: 100%;
    box-sizing: border-box;
	border-width: 1px 1px 1px 10px;
	border-left: 9px solid #04ada8;
	border-bottom: 1px solid #dfdfdf;
`;
const MRModalHeaderText = styled.div`
	font-weight: 600;
	font-size: 20px;
	float: left;
	margin-top: 24px;
	margin-left: 35px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalSubHeaderText = styled.div`
	font-size: 13px;
	float: left;
	margin-left: 35px;
	margin-bottom: 20px;
	margin-top: 10px;
	color: #1A3552;
	font-family: 'Montserrat', sans-serif;
`;
const MRModalHeaderCloseBtn = styled.div`
	position: absolute;
	top: 8px;
	right: 10px;
	box-sizing: border-box;
	margin-top: 21px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
	font-weight: 200;
	color: #656565;
`;
const MRModalBody = styled.div`
	position: relative;
	display: block;
	box-sizing: border-box;
	background-color: #F1F6F7;
	padding: 35px 35px 35px 35px;
	border-radius: 4px;
`;
const ClearBoth = styled.div`
	clear: both;
`;
const MRModalLabel = styled.div`
	color: #030303;
	font-weight: 600;
	font-size: 13px;
	margin-bottom: 9px;
	font-family: 'Montserrat', sans-serif;
`;

const Row = styled.div`
	margin-bottom: 10px;
`;
// const InfoDiv = styled.div`
// 	width: 100%;
// 	background-color: rgb(214 230 243);
// 	border: 1px solid rgb(173 177 181);
// 	border-radius: 3px;
// 	padding: 10px 25px;
// 	box-sizing: border-box;
// `;
const MRModalFooter = styled.div`
	padding: 17px;
	background-color: white;
	border-style: solid;
	border-width: 1px;
	border-color: #f3f7fb;
  	border-top: 1px solid #dfdfdf;
`;
const MRModalNextBtn = styled.button`
    box-sizing: border-box;
    color: #ffffff;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    background-color: #37ada7;
    border: 1px solid #37ada7;
    padding: 10px 20px;
`;

const TextBox = styled.input`
	height: 41px;
    border: 1px solid #DBDBDB;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
`;

const CloseButton = styled.button`
	box-sizing: border-box;
	color: #2d2c2c;
	border-radius: 4px;
	font-size: 13px;
	cursor: pointer;
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	padding: 10px 20px;
	font-weight: 600;
`;
const MRModalSelect = styled.select`
	display: block;
	box-sizing: border-box;
	height: 40px;
	width: 20%;
	background-color: #fff;
	border: 1px solid #ffffff;
	border-radius: 3px;
	box-shadow: 0 0 4px #c4c4c4;
	font-family: "Montserrat", sans-serif;
	color: #000000;
	margin-top: 10px;
	margin-bottom: 10px;
`;
const Section30 = styled.div`
	width: 30%;
	float: left;
	box-sizing: border-box;
`;

const MRModalTextarea = styled.textarea`
	height: 130px;
	width: calc(100% - 13px);
	border: 1px solid #ffffff;
	border-radius: 3px;
	background-color:#ffffff
	box-shadow: 0 0 4px #c4c4c4;
	resize: none;
	padding: 5px;
	color: #282828;
	font-family: 'Montserrat', sans-serif;
`;

class ManageSecurityRegister extends React.Component {
    state = {
        ready: false,
        type:{},
        selected_security: null,
        selected_currency: null,
        security_types: null,
        currencies: null,
        security_identifier: null
    }
    constructor() {
      super();
      this.api = new APICall();
    }
    componentDidMount(){ 
    	let type = this.props.type;
    	let filters = this.props.filters;
    	let security_types = [];
    	let currencies = [];
    	let security_identifier = [];
    	for(let key in filters) {
    		if(filters[key].lookup_id === "security_types"){
    			security_types.push({id: key, name: filters[key].name})
    		}
    		if(filters[key].lookup_id === "currencies"){
    			currencies.push({id: key, name: filters[key].name})
    		}
    		if(filters[key].lookup_id === "security_identifier_types") {
    			security_identifier.push({id: key, name: filters[key].name})
    		}
    	}   
    	let selected_currency = currencies[0]; 
    	let selected_security = security_types[0]; 
    	if(type['id'] > 0) {
    		let lookup_json = type['lookup_json'];
    		selected_security = {id : lookup_json.security_type_id, name: filters[lookup_json.security_type_id.toString()].name};
    		selected_currency = {id : lookup_json.currency_id, name: filters[lookup_json.currency_id.toString()].name};
    	}
    	console.log("selected_currency", selected_currency)
    	type['lookup_json'].security_type_id = selected_security.id;
    	type['lookup_json'].currency_id = selected_currency.id;
        
        this.setState({ready: true, type: type, filters: this.state.filters, 
        	selected_security: selected_security,selected_currency: selected_currency, security_types: security_types, currencies: currencies, security_identifier: security_identifier})
    }

    handleChange = (evt) => {      
    	let type = this.state.type;
    	type[evt.target.name]  = evt.target.value
        this.setState(type);
    }

    SaveRole = (event) => {
        event.preventDefault();
		if (this.state.type.name === "") {
			alert("Name must not be left blank");
			return false;
		}
		if (Object.keys(this.state.type.lookup_json.identifier_types).length === 0 && Store.getStoreData("module_config").general.one_security_id_required === true) {
			alert("Atleast one identifier is mandatory");
			return false;
		} else {
			let blank = true;
			for (let key in this.state.type.lookup_json.identifier_types) {
				if (this.state.type.lookup_json.identifier_types[key.toString()] !== "") blank = false;
			}
			if (blank === true && Store.getStoreData("module_config").general.one_security_id_required === true) {
				alert("Atleast one identifier is mandatory");
				return false;
			}
		}
        const postData = { command: "save_lookup"};
        let lookup_json = {};
        lookup_json['name'] = this.state.type["name"];
        postData['lookup_json'] = this.state.type['lookup_json'];
        // postData['lookup_json']['is_restricted'] = true;
        postData['name'] = this.state.type["name"];
        postData['status'] = 1;
        postData['description'] = '';
        postData['lookup_id'] = 'security_register';
        postData['id'] = this.state.type['id'];
        postData['lookup_json']['is_restricted'] = parseInt(postData['id']) === 0 ? true : postData['lookup_json']['is_restricted'];
		postData['res_id'] = this.props.res_id;
		postData['isManageRes'] = this.props.isManageRes;
        console.log("postData", postData)
        this.api.command(postData, this.saveroleTypes);
    };

    saveroleTypes = (result) => {
    	console.log("result", result)
    	let msg = this.state.type["id"] === 0 ? "Security Restricted Register has been created" : "Security Restricted Register has been updated";
    	alert(msg);
    	this.props.refreshParent();
    }

    closeView = () => {
        this.props.closeModal();
    };

    changeRequeterSecurityType = (data) => {
		let selected_security = this.state.security_types.find(item => item.id === data);
		let type = this.state.type;
		type.lookup_json['security_type_id'] = selected_security.id;
		this.setState({selected_security, type});
	}

    changeRequeterCurrency = (data) => {
		let selected_currency = this.state.currencies.find(item => item.id === data);
		let type = this.state.type;
		type.lookup_json['currency_id'] = selected_currency.id;
		this.setState({selected_currency, type});
	}


	handleChangeD = (evt) => {
	    let type = this.state.type;
	    type.lookup_json.identifier_types[evt.target.name] = evt.target.value;
	    this.setState({type})
	};

	handleChangereason = (evt) => {
	    let type = this.state.type;
	    type.lookup_json[evt.target.name] = evt.target.value;
	    this.setState({type})
	};

    render () {
        if(!this.state.ready){
            return(<div>Loading...</div>)
        }	
		return (
			<div>
				<ModalWraper style={{width: 'changeWidgh' in this.props ? "100%" : "70%"}}>
	                <MRModalContainer>
	                    <MRModalHeader>
							<MRModalHeaderText>Create Restricted Security</MRModalHeaderText>
							<MRModalHeaderCloseBtn onClick={this.closeView}>
					            <FaTimes />
					        </MRModalHeaderCloseBtn>
					        <ClearBoth />
					        <MRModalSubHeaderText>Complete the required fields to create a new Restricted Security</MRModalSubHeaderText>
						</MRModalHeader>
	                    <MRModalBody>
	                        <Row>
	                            <MRModalLabel>Name</MRModalLabel>
	                            <TextBox type="text" value={this.state.type["name"]} name="name" onChange={this.handleChange}/>
	                        </Row>
	                        
                            <Row>
								<MRModalLabel>Security Type</MRModalLabel>
								<CslSimpleDropDown options={this.state.security_types} selected={this.state.selected_security} setSelection={this.changeRequeterSecurityType} />
							</Row>
	                        <Row>
								<MRModalLabel>Currency</MRModalLabel>
	                            <Section30>
								    <CslSimpleDropDown options={this.state.currencies} selected={this.state.selected_currency} setSelection={this.changeRequeterCurrency} />
	                            </Section30>
	                            <ClearBoth />
							</Row>
	                        
							{
							    this.state.security_identifier.map((key) => {
							        return (
							            <Row>
							                <MRModalLabel>{key.name}</MRModalLabel>
							                <TextBox type="text" name={key.id} value={this.state.type.lookup_json.identifier_types[key.id.toString()]} onChange={this.handleChangeD} />
							            </Row>
							        );
							    })
							}

							<Row>
							    <MRModalLabel>Reason</MRModalLabel>
							    <MRModalTextarea value={this.state.type.lookup_json["reason"]} name="reason" onChange={this.handleChangereason}/>
							</Row>
	                    </MRModalBody>
	                    <MRModalFooter>
	                        <MRModalNextBtn style={{float: "right"}} onClick={this.SaveRole}>SUBMIT</MRModalNextBtn>
	                        <CloseButton style={{float: "right", marginRight: "20px"}} onClick={this.closeView}>CLOSE</CloseButton>
	                        <ClearBoth />
	                    </MRModalFooter>
	                </MRModalContainer>
	            </ModalWraper>
            </div>
        )
    }
}
export default ManageSecurityRegister;
